<template>
  <Transition name="fade">
    <div v-if="show" class="error-message">
      <p class="error-message__description">
        {{ message }}
      </p>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'CommonErrorMessage',
  props: {
    message: {
      type: String,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>
.error-message {
  padding: 10px;
  border: 1px solid #F44336;
  border-radius: 5px;
  background-color: #FFCDD2;
}
.error-message__description {
  margin-bottom: 0;
  color: #F44336;
  text-align: center;
  font-size: 12px;
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate3d(10px, 0, 0);
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  transform: translate3d(0px, 0, 0);
  opacity: 1;
}
</style>